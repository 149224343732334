type Product = {
  locations?: { name: string }[];
  price?: null | {
    rrp: number | null;
    displayPrice: number | null;
  };
};

const getLocationInfo = (product: Product): number | string | null => {
  if (!Array.isArray(product?.locations) || !product?.locations?.length) return null;

  const locations = product.locations;

  if (locations.length > 1) return locations.length;
  if (locations[0]?.name) return product.locations[0].name;

  return null;
};

const getPriceInfo = (
  product: Product
): { currentPrice: number | null; pastPrice: number | null; badge: string | null } => {
  const currentPrice = product?.price?.displayPrice ?? null;
  const rrp = product?.price?.rrp ?? null;

  if (!currentPrice || !rrp) {
    return { currentPrice: null, pastPrice: null, badge: null };
  }

  const pastPrice = rrp > currentPrice ? rrp : null;
  const badge = pastPrice && pastPrice > currentPrice ? 'SALE' : null;

  return { currentPrice, pastPrice, badge };
};

export { getLocationInfo, getPriceInfo };
