import { VEDBackendApi, type ProductCard } from '@virginexperiencedays/backend';

export const fetchVPApi = async (
  baseUrl: string,
  apiKey: string,
  mediaCdnBaseUrl: string,
  productIds: string[]
): Promise<ProductCard[]> => {
  if (!productIds.length) return [];

  const api = new VEDBackendApi({ baseUrl, apiKey, mediaCdnBaseUrl });
  const result = await api.bff.productCards.find({ id: productIds });
  return result?.items ?? [];
};
