export enum ErrorCodes {
  // API
  API_MISSING_BASE_URL = '@virginexperiencedays/backend::api::missing-base-url',
  API_ENDPOINT_NOT_DEFINED = '@virginexperiencedays/backend::api::not-defined',

  // Endpoint
  ENDPOINT_MISSING_ENDPOINT = '@virginexperiencedays/backend::endpoint::missing-endpoint',
  ENDPOINT_MISSING_BASE_URL = '@virginexperiencedays/backend::endpoint::missing-base-url',
  ENDPOINT_UNRETRYABLE_ERROR = '@virginexperiencedays/backend::endpoint::unretryable-error',
  ENDPOINT_NOT_OK = '@virginexperiencedays/backend::endpoint::not-ok',
  ENDPOINT_NO_SLUG = '@virginexperiencedays/backend::endpoint:no-slug',
  ENDPOINT_SLUG_INVALID_CHARACTERS = '@virginexperiencedays/backend::endpoint:slug-invalid-characters',

  // Formatter
  INVALID_RESPONSE_FORMAT = '@virginexperiencedays/backend::formatter::invalid-response-format',

  // JSON
  INVALID_JSON = '@virginexperiencedays/backend::rich-error::invalid-json',

  // Fallback
  ENDPOINT_UNKNOWN_ERROR = '@virginexperiencedays/backend::rich-error::unknown-error',
}

export function handleRichError(errorCode: ErrorCodes, error: unknown): string {
  if (error instanceof RichError) return error.toJSON();

  if (error instanceof Error) {
    return new RichError({ errorCode, message: error.message }).toJSON();
  }

  return new RichError({
    errorCode: ErrorCodes.ENDPOINT_UNKNOWN_ERROR,
    message: 'Unknown error',
  }).toJSON();
}

type RichErrorOpts = {
  errorCode: ErrorCodes;
  message: string;
  metadata?: Record<string, any>;
};

export class RichError extends Error {
  errorCode: ErrorCodes;
  message: string;
  metadata?: Record<string, any>;

  constructor(opts: RichErrorOpts) {
    super(opts.message);
    this.errorCode = opts.errorCode;
    this.message = opts.message;
    this.metadata = opts.metadata;
  }

  toJSON(): string {
    const { errorCode, message, ...rest } = this.metadata ?? {};

    try {
      return JSON.stringify({
        // define errorCode and message first, for readability
        errorCode: this.errorCode,
        message: this.message,
        // spread metadata, but make sure metadata.errorCode and metadata.message don't clobber our own
        metadataErrorCode: errorCode,
        metadataMessage: message,
        ...rest,
      });
    } catch (error) {
      return JSON.stringify({
        errorCode: ErrorCodes.INVALID_JSON,
        message: error instanceof Error ? error.message : 'Error parsing metadata',
      });
    }
  }
}
