import { Image } from '@virginexperiencedays/components-v2/src/layout/Image';

type Props = {
  color?: 'default' | 'white';
  className?: string;
  imageClassName?: string;
};

export const SiteLogo = ({ color = 'default', className, imageClassName }: Props) => {
  const logoPaths = {
    default: process.env.NEXT_PUBLIC_SITE_LOGO_DEFAULT || '/assets/icons/SiteLogo.svg',
    white: process.env.NEXT_PUBLIC_SITE_LOGO_WHITE || '/assets/icons/SiteLogoWhite.svg',
  };
  const homeLink = process?.env?.NEXT_PUBLIC_HOME_LINK;
  const src = logoPaths?.[color] || logoPaths.default;
  const normalizedSrc = src.startsWith('/') ? src : `/${src}`;

  const isValidUrl = (url: string) => {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  };

  if (!isValidUrl(homeLink)) {
    console.error('Invalid homeLink provided to SiteLogo');
    return null;
  }

  return (
    <Image
      src={`${homeLink}${normalizedSrc}`}
      alt="Virgin Experience Days Logo"
      width={100}
      height={40}
      loading="eager"
      className={className}
      imageClassName={imageClassName}
    />
  );
};
