import { HTMLAttributes, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

import { cn } from '@virginexperiencedays/tailwind';
import { useScrollLock } from '@virginexperiencedays/hooks';
import { BodyText } from '@virginexperiencedays/components-v2/src/typography/BodyText';
import { Button } from '@virginexperiencedays/components-v2/src/layout/Button';
import { SiteLogo } from '../SiteLogo';

export type CookiesModalV2Props = {
  show: boolean;
  href?: string;
  onClick?: () => void;
  className?: string;
} & HTMLAttributes<HTMLDivElement>;

export const CookiesModalV2 = ({ className, show, href, onClick }: CookiesModalV2Props) => {
  const router = useRouter();
  const dialogRef = useRef<HTMLDivElement>(null);
  const { lockScroll, unlockScroll } = useScrollLock();

  useEffect(() => {
    const handleRouteChange = () => {
      if (show) {
        lockScroll();
      }
    };
    handleRouteChange();
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      unlockScroll();
    };
  }, [show, router.events, lockScroll, unlockScroll]);

  const createKeydownHandler = (dialog: HTMLDivElement) => (e: KeyboardEvent) => {
    if (e.key !== 'Tab') return;

    e.preventDefault();
    const focusableElements = dialog.querySelectorAll<HTMLElement>('button, [href]');
    const firstFocusable = focusableElements[0];
    const lastFocusable = focusableElements[focusableElements.length - 1];
    const currentIndex = Array.from(focusableElements).indexOf(
      document.activeElement as HTMLElement
    );

    if (e.shiftKey) {
      (currentIndex <= 0 ? lastFocusable : focusableElements[currentIndex - 1]).focus();
    } else {
      (currentIndex === focusableElements.length - 1
        ? firstFocusable
        : focusableElements[currentIndex + 1]
      ).focus();
    }
  };

  useEffect(() => {
    if (!show) return;

    const dialog = dialogRef.current;
    if (!dialog) return;
    const originalUserSelect = document.body.style.userSelect;
    document.body.style.userSelect = 'none';

    const handleKeyDown = createKeydownHandler(dialog);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.body.style.userSelect = originalUserSelect;
    };
  }, [show, createKeydownHandler]);

  if (!show) return null;

  return (
    <div
      ref={dialogRef}
      className={cn('z-[600] select-text bg-transparent p-4 pb-[16px] sm:pb-[40px]', className)}
      aria-describedby="cookie-policy-summary"
    >
      <div className="w-full max-w-[955px] rounded-lg bg-background-page px-4 py-6 shadow-lg md:p-6 dark:bg-background-primary-faded-lighter">
        <SiteLogo imageClassName="h-10" className="mb-2 flex justify-center md:justify-start" />
        <div className="flex flex-col items-center gap-4 md:flex-row">
          <BodyText
            id="cookie-policy-summary"
            className="text-center font-inter text-sm text-neutral sm:mb-0 sm:flex-1 md:text-left"
          >
            We use cookies to give you the best experience and make sure you see relevant ads on
            other sites. By continuing you agree to this.{' '}
            {/* Uses <a /> to navigate to legacy C# page */}
            <a
              href={href}
              className="decoration-skip-ink-none whitespace-nowrap text-neutral underline underline-offset-[1px]"
            >
              Find out more.
            </a>
          </BodyText>
          <Button
            variant="primary"
            onClick={onClick}
            className="w-full md:max-w-44"
            aria-label="Accept cookies"
            autoFocus
          >
            Accept
          </Button>
        </div>
      </div>
    </div>
  );
};
