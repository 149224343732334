import { FetchEndpoint, type Endpoint } from './endpoint';
import {
  formatPDPInfo,
  formatReviews,
  formatProductCards,
  formatCollectionChoiceInfo,
  formatBlogProducts,
} from './formatters';
import { ErrorCodes, RichError } from './utils/rich-error';
import {
  type AlgoliaProductsQuery,
  type BlogProduct,
  type BlogProductsQuery,
  type Category,
  type CategoryQuery,
  type PDPInfo,
  type PDPInfoQuery,
  type PaginatedResult,
  type ProductPage,
  type ProductPageQuery,
  type ProductStock,
  type ProductStockQuery,
  type ProductCard,
  type ProductCardQuery,
  type Review,
  type ReviewQuery,
  type RecentProductReviewsQuery,
  type CollectionChoiceInfoQuery,
  type CollectionChoiceInfo,
} from './types';

type Opts = {
  apiKey?: string;
  baseUrl?: string;
  mediaCdnBaseUrl?: string;
};

/**
 * This is a thin API client for the Backend API (for products, categories,
 * reviews, and related information). Each endpoint is exposed as a direct
 * property mirroring the REST API endpoint name, e.g.
 *
 * const api = new VEDBackendApi();
 * const result = await api.bff.pdpInfo.find();
 *
 * See {@link Endpoint} for the implementation of endpoint methods.
 */
export class VEDBackendApi {
  reviews: Endpoint<ReviewQuery, PaginatedResult<Review>>;
  categories: Endpoint<CategoryQuery, PaginatedResult<Category>>;
  bff: {
    pdpInfo: Endpoint<PDPInfoQuery, PaginatedResult<PDPInfo>>;
    productPages: Endpoint<ProductPageQuery, PaginatedResult<ProductPage>>;
    productStock: Endpoint<ProductStockQuery, PaginatedResult<ProductStock>>;
    productCards: Endpoint<ProductCardQuery, PaginatedResult<ProductCard>>;
    recentProductReviews: Endpoint<RecentProductReviewsQuery, PaginatedResult<Review>>;
    algoliaProducts: Endpoint<AlgoliaProductsQuery, PaginatedResult<PDPInfo>>;
    collectionChoiceInfo: Endpoint<CollectionChoiceInfoQuery, CollectionChoiceInfo>;
    blogProducts: Endpoint<BlogProductsQuery, PaginatedResult<BlogProduct>>;
  };

  constructor(opts?: Opts) {
    const {
      apiKey = process.env.BACKEND_API_KEY,
      baseUrl = process.env.NEXT_PUBLIC_BACKEND_API_BASE_URL,
      mediaCdnBaseUrl = process.env.BACKEND_API_MEDIA_CDN_BASE_URL ??
        'https://images.virginexperiencedays.co.uk/images/product/main',
    } = opts ?? {};
    if (!baseUrl) {
      throw new RichError({
        errorCode: ErrorCodes.API_MISSING_BASE_URL,
        message: 'Missing baseUrl for VEDBackendApi',
      });
    }

    // initialize endpoints
    const endpointOpts = {
      key: apiKey,
      baseUrl,
      mediaCdnBaseUrl,
    };
    this.reviews = new FetchEndpoint('reviews', { ...endpointOpts, formatter: formatReviews });
    this.categories = new FetchEndpoint('categories', endpointOpts);
    this.bff = {
      pdpInfo: new FetchEndpoint('bff/pdp-info', { ...endpointOpts, formatter: formatPDPInfo }),
      productPages: new FetchEndpoint('bff/product-pages', endpointOpts),
      productStock: new FetchEndpoint('bff/product-stock', endpointOpts),
      productCards: new FetchEndpoint('bff/product-cards', {
        ...endpointOpts,
        formatter: formatProductCards,
      }),
      recentProductReviews: new FetchEndpoint('bff/recent-product-reviews', endpointOpts),
      algoliaProducts: new FetchEndpoint('bff/algolia-products', {
        ...endpointOpts,
        formatter: formatPDPInfo,
      }),
      collectionChoiceInfo: new FetchEndpoint('bff/collection-choice-info', {
        ...endpointOpts,
        formatter: formatCollectionChoiceInfo,
      }),
      blogProducts: new FetchEndpoint('bff/blog-product-info', {
        ...endpointOpts,
        formatter: formatBlogProducts,
      }),
    };
  }
}
