import { cn } from '@virginexperiencedays/tailwind';
import { useDyCustomEventTrigger } from './hooks';
import type { HTMLAttributes } from 'react';

enum Selectors {
  SIMPLE_PROMO_BANNER = 'simple-promo-banner',
  HTML_CONTENT = 'simple-promo-banner-content',
}

export const SimplePromoBanner = ({ className }: HTMLAttributes<HTMLDivElement>) => {
  useDyCustomEventTrigger('dy:promo-simple-mounted');

  return (
    <div
      data-dy={Selectors.SIMPLE_PROMO_BANNER}
      className={cn(
        'grid grid-cols-[1fr_auto] gap-x-4 gap-y-1 rounded bg-[#FFF1F1] px-4 py-3',
        className
      )}
    >
      <div data-title className="col-span-2 self-end md:pr-6 lg:hidden lg:self-start" />

      <div className="flex flex-wrap gap-x-4 gap-y-1">
        <div data-title className="hidden self-end pr-6 lg:inline lg:self-start" />
        <div data-content />
      </div>
      {/* We need to use anchor instead of Link to allow href updates from DY */}
      <a
        data-exclusions
        href="#"
        className="text-neutral-faded self-start self-end text-right text-xs leading-6 underline"
      >
        T&C’s Apply
      </a>
    </div>
  );
};
