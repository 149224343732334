import { useEffect, useRef, type RefObject } from 'react';

export const useScrollToRef = <T extends HTMLDivElement>(): {
  ref: RefObject<T>;
  scrollToRef: () => void;
} => {
  const ref = useRef<T>(null);
  const animationFrameId = useRef<number | null>(null);

  const scrollToRef = () => {
    if (!ref.current || animationFrameId.current) return;

    if (animationFrameId.current) {
      cancelAnimationFrame(animationFrameId.current);
      animationFrameId.current = null;
    }

    const scroll = () => {
      const targetPosition = ref.current.getBoundingClientRect().top;
      const scrollAmount = Math.max(targetPosition * 0.1, 1);

      window.scrollBy(0, scrollAmount);

      if (Math.abs(targetPosition) > 1) {
        animationFrameId.current = requestAnimationFrame(scroll);
      } else {
        animationFrameId.current = null;
      }
    };

    animationFrameId.current = requestAnimationFrame(scroll);
  };

  useEffect(() => {
    const cancelScroll = () => {
      if (!animationFrameId.current) return;

      cancelAnimationFrame(animationFrameId.current);
      animationFrameId.current = null;
    };

    window.addEventListener('wheel', cancelScroll, { passive: true });
    window.addEventListener('touchmove', cancelScroll, { passive: true });

    return () => {
      animationFrameId.current && cancelAnimationFrame(animationFrameId.current);
      window.removeEventListener('wheel', cancelScroll);
      window.removeEventListener('touchmove', cancelScroll);
    };
  }, []);

  return { ref, scrollToRef };
};
