export enum TrackingEventName {
  filterInteraction = 'filterInteraction',
  productCardInteraction = 'productCardInteraction',
  grcInteraction = 'grcInteraction',
  pdpInteraction = 'pdpInteraction',
  userInteraction = 'userInteraction',
  productClick = 'productClick',
  genericEvent = 'genericEvent',
  cookieInteraction = 'cookieInteraction',
}

export enum GrcCustomerState {
  Valid = 'Valid',
  NoCards = 'NoCards',
  Invalid = 'Invalid',
}

export enum GrcInteraction {
  LoyaltyBanner = 'click on Loyalty Banner',
  CheckoutNow = 'click on Checkout Now',
  EditBasket = 'click on Edit Basket',
  SkipLink = 'click on Skip Link',
}

export enum GrcPageType {
  Landing = 'Landing',
  Pdp = 'PDP',
}
