import { ErrorCodes, handleRichError } from '../utils/rich-error';
import type { PaginatedResult, Review } from '../types';

export function formatReviews(response: PaginatedResult<unknown>): PaginatedResult<Review> {
  try {
    if (typeof response !== 'object' || response === null) {
      throw new Error('Unable to format reviews. Invalid response format');
    }

    const items = Array.isArray(response?.items) ? response.items : [];
    const total = typeof response?.total === 'number' ? response.total : items.length;

    const validatedReviews = mustValidateReviews(items);

    return { total, items: validatedReviews };
  } catch (error) {
    console.error(handleRichError(ErrorCodes.INVALID_RESPONSE_FORMAT, error));

    return { total: 0, items: [] };
  }
}

function mustValidateReviews(reviews: unknown): Review[] {
  if (!Array.isArray(reviews)) {
    throw new Error('Unable to validate reviews. Invalid reviews format');
  }

  const validatedReviews = reviews.reduce((acc, review) => {
    if (typeof review !== 'object' || review === null) return acc;

    acc.push({
      productReviewId: typeof review?.productReviewId === 'number' ? review.productReviewId : null,
      sku: typeof review?.sku === 'string' ? review.sku : null,
      reviewerName: typeof review?.reviewerName === 'string' ? review.reviewerName : null,
      title: typeof review?.title === 'string' ? review.title : null,
      content: typeof review?.content === 'string' ? review.content : null,
      rating: typeof review?.rating === 'number' ? review.rating : null,
      dateCreated: typeof review?.dateCreated === 'string' ? review.dateCreated : null,
    });

    return acc;
  }, []);

  return validatedReviews;
}
