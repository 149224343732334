import { useEffect, useState } from 'react';

export const useDyCustomEventTrigger = (eventName: string) => {
  useEffect(() => {
    if (typeof window === 'undefined') return;
    const event = new CustomEvent(eventName, { detail: true });
    document.dispatchEvent(event);
  }, []);
};

export const useDyCustomEventListener = (eventName: string) => {
  const [triggered, setTriggered] = useState(false);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const handleEvent = () => {
      setTriggered(true);
    };

    document.addEventListener(eventName, handleEvent);
    return () => {
      document.removeEventListener(eventName, handleEvent);
    };
  }, []);

  return triggered;
};
