import {
  useMemo,
  useState,
  forwardRef,
  type SVGProps,
  type HTMLAttributes,
  type InputHTMLAttributes,
  type MouseEventHandler,
  type ChangeEvent,
  type FormEvent,
} from 'react';
import debounce from 'lodash.debounce';
import z from 'zod';

import { cn } from '@virginexperiencedays/tailwind';
import { useCopyToClipboard } from '@virginexperiencedays/hooks';

import { Button } from '../../../layout/Button';
import { useDyCustomEventTrigger } from './hooks';

enum Selectors {
  PROMO_CODE_SIGN_UP = 'promo-code-sign-up',
  SIGN_UP_FORM = 'sign-up-form',
  CODE_FORM = 'code-form',
  CODE_INPUT = 'code-input',
  CODE_COPY_BUTTON = 'code-copy-button',
}

export const PromoCodeSignUpBanner = ({ className }: HTMLAttributes<HTMLDivElement>) => {
  useDyCustomEventTrigger('dy:promo-code-mounted');

  return (
    <div
      data-dy={Selectors.PROMO_CODE_SIGN_UP}
      className={cn(
        'flex flex-col gap-2 rounded bg-[#FFF1F1] px-4 py-3',
        'md:grid md:grid-cols-[auto_auto_1fr] md:grid-rows-2 md:gap-x-4',
        'lg:flex lg:flex-col lg:gap-x-2',
        'xl:grid xl:grid-cols-[auto_auto_1fr] xl:grid-rows-2 xl:gap-x-4',
        className
      )}
    >
      <div data-title className="md:col-span-2 md:self-end lg:self-auto" />
      <div data-subtitle className="md:row-start-2" />

      <SignUpForm
        data-dy={Selectors.SIGN_UP_FORM}
        className={cn('hidden', 'md:row-span-2 md:self-center')}
      />
      <CodeForm
        data-dy={Selectors.CODE_FORM}
        className={cn('hidden', 'md:row-span-2 md:self-center')}
      />

      {/* We need to use anchor instead of Link to allow href updates from DY */}
      <a
        data-exclusions
        href="#"
        className="text-persistent-text-faded self-start text-xs leading-4 underline"
      >
        T&C’s Apply
      </a>
    </div>
  );
};

export const SignUpForm = ({ className, ...props }: HTMLAttributes<HTMLFormElement>) => {
  const {
    isSubmitDisabled,
    error,
    email,
    handleBlur: handleEmailBlur,
    handleChange: handleEmailChange,
    handleSubmit,
  } = useEmailValidation();

  return (
    <form
      {...props}
      className={cn('flex w-full flex-col gap-1', className)}
      onSubmit={handleSubmit}
    >
      <div className="flex w-full flex-row flex-nowrap gap-2">
        <IconInput className="flex-1">
          <span className="p-4">
            <EmailIcon />
          </span>
          <input
            aria-invalid={!!error}
            aria-describedby="dy-signup-email-error"
            type="email"
            name="email"
            placeholder="Enter your email..."
            className="text-persistent-text-dark placeholder-persistent-text-dark-faded w-full min-w-0 flex-1 p-4 pl-0 pr-4 outline-none"
            onBlur={handleEmailBlur}
            onChange={handleEmailChange}
            value={email}
          />
        </IconInput>
        <Button
          variant="primary"
          className="text-md min-w-[100px] lg:min-w-[180px]"
          disabled={isSubmitDisabled}
        >
          Sign Up
        </Button>
      </div>
      {!!error && (
        <p
          id="dy-signup-email-error"
          role="alert"
          className="m-0 line-clamp-1 text-xs text-red-500"
        >
          {error}
        </p>
      )}
    </form>
  );
};

const CodeForm = ({ className, ...props }: HTMLAttributes<HTMLFormElement>) => {
  const { handleCopy, success } = useCopyToClipboard(3000);

  const handleFormCopy: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    const target = e.target as HTMLButtonElement;
    const siblingInput = target.previousElementSibling?.querySelector('input');
    const code = siblingInput?.value ?? '';
    handleCopy(code);
  };

  return (
    <form {...props} className={cn('flex w-full flex-row flex-nowrap gap-2', className)}>
      <div className="relative flex-1">
        <IconInput className="[&>input]:pl-4 [&>input]:tracking-wider">
          <input
            data-dy={Selectors.CODE_INPUT}
            name="code"
            disabled
            className="text-persistent-text-dark placeholder-persistent-text-dark-faded w-full min-w-0 flex-1 p-4 pl-0 pr-4 outline-none"
          />
        </IconInput>
        <ToolTip
          className={cn(
            'absolute right-4 top-[calc(100%+.5rem)] transition-opacity duration-300',
            'after:bottom-full after:border-b-[4px]', // position bottom
            'md:bottom-[calc(100%+.5rem)] md:top-auto md:after:bottom-0 md:after:top-full md:after:border-b-0 md:after:border-t-[4px]', // position top
            'lg:bottom-auto lg:top-[calc(100%+.5rem)] lg:after:bottom-full lg:after:top-auto lg:after:border-b-[4px] lg:after:border-t-0', // position bottom
            'xl:bottom-[calc(100%+.5rem)] xl:top-auto xl:after:bottom-auto xl:after:top-full xl:after:border-b-0 xl:after:border-t-[4px]', // position top
            success ? 'opacity-100' : 'opacity-0'
          )}
        />
      </div>
      <Button
        variant="primary"
        data-dy={Selectors.CODE_COPY_BUTTON}
        onClick={handleFormCopy}
        className="text-md min-w-[100px] lg:min-w-[180px]"
      >
        <span className="lg:hidden">Copy</span>
        <span className="hidden lg:inline">Copy Code</span>
      </Button>
    </form>
  );
};

const IconInput = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>(
  ({ className, children, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        'border-border-neutral dark:border-border-neutral-inverse flex flex-nowrap items-center overflow-hidden rounded border bg-white shadow-[0px_1px_1px_0px_rgba(0,0,0,0.2)]',
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
);
IconInput.displayName = 'IconInput';

const ToolTip = ({ className }: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'user-select-none pointer-events-none rounded bg-[#10B981] px-4 py-3',
      'after:content after:absolute after:left-1/2 after:h-0 after:w-0 after:-translate-x-1/2 after:border-x-[6px] after:border-transparent after:border-y-[#10B981]',
      className
    )}
  >
    <p className="flex flex-row items-center gap-1 text-sm leading-4 text-white">
      <span>
        <CheckIcon />
      </span>
      <span>Copied!</span>
    </p>
  </div>
);

const EmailIcon = ({ width = 18, height = 14, className }: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M17.125 2.625V11.375C17.125 11.8723 16.9275 12.3492 16.5758 12.7008C16.2242 13.0525 15.7473 13.25 15.25 13.25H2.75C2.25272 13.25 1.77581 13.0525 1.42417 12.7008C1.07254 12.3492 0.875 11.8723 0.875 11.375V2.625M17.125 2.625C17.125 2.12772 16.9275 1.65081 16.5758 1.29917C16.2242 0.947544 15.7473 0.75 15.25 0.75H2.75C2.25272 0.75 1.77581 0.947544 1.42417 1.29917C1.07254 1.65081 0.875 2.12772 0.875 2.625M17.125 2.625V2.8275C17.125 3.14762 17.0431 3.46242 16.887 3.74191C16.7309 4.0214 16.5059 4.25628 16.2333 4.42417L9.98333 8.27C9.68767 8.45211 9.34725 8.54854 9 8.54854C8.65275 8.54854 8.31233 8.45211 8.01667 8.27L1.76667 4.425C1.4941 4.25711 1.26906 4.02224 1.11297 3.74275C0.95689 3.46325 0.874965 3.14845 0.875 2.82833V2.625"
      stroke="#2B3240"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const CheckIcon = ({ width = 16, height = 10, className }: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0243 0.175736C11.2586 0.410051 11.2586 0.789949 11.0243 1.02426L4.62429 7.42426C4.38997 7.65858 4.01007 7.65858 3.77576 7.42426L0.57576 4.22426C0.341446 3.98995 0.341446 3.61005 0.57576 3.37574C0.810075 3.14142 1.18997 3.14142 1.42429 3.37574L4.20002 6.15147L10.1758 0.175736C10.4101 -0.0585786 10.79 -0.0585786 11.0243 0.175736Z"
      fill="white"
    />
  </svg>
);

export const useEmailValidation = () => {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const validate = (value: string) => {
    const isValid = z.string().email().safeParse(value).success;

    setIsSubmitDisabled(!isValid);
    setError(isValid ? '' : 'Please enter a valid email address');

    return isValid;
  };

  const debouncedValidate = useMemo(
    () =>
      debounce((value: string) => {
        validate(value);
      }, 300),
    []
  );

  const reset = () => {
    // reset submit button and clear error messages
    setIsSubmitDisabled(false);
    setError('');
  };

  const handleBlur = () => {
    // reset field if empty
    if (email === '') {
      reset();
      return;
    }

    // validate if has value
    validate(email);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim().toLowerCase();
    setEmail(value);
    debouncedValidate(value);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    const isValid = validate(email);

    // prevent form submission if invalid
    if (!isValid) {
      e.preventDefault();
      return false;
    }

    // let original event propagate
  };

  return { isSubmitDisabled, error, email, validate, handleBlur, handleChange, handleSubmit };
};
