// TODO LP_5659: apply logic to extend the promotion functionality for Valentines and Mother's Day
// Priority: Post-release

import { useHeaderContext, type SearchStateType } from '../../../context/HeaderContext';
import { Cookie } from '../../../enums';
import { LocalStorage } from '../../../enums/LocalStorage';
import useAlgolia, { type AlgoliaEnvVars } from '../../../hooks/useAlgolia';
import useCMSGlobal from '../../../hooks/useCMSGlobal';
import { useValidateProducts } from '../../../hooks/useValidateProducts';
import { vpToDataLayer, productToDataLayer } from '../../../utils/addToDataLayer';
import { setDomainCookie } from '../../../utils/cookie';
import { getViewedProducts } from '../../../utils/getViewedProducts';

import SearchResultCards, { SearchResultCardsProps } from './SearchResult';
import SearchTermSuggestions, { SuggestionsProps } from './SearchTermSuggestions';

import type { SearchResultCardProps } from '../../../types';

type SearchResultWrapperProps = {
  handleLinkClicks: () => void;
};

export const SearchResultWrapper = ({ handleLinkClicks }: SearchResultWrapperProps) => {
  const {
    searchState,
    prismicRepoEndpoint,
    prismicToken,
    productApiUrl,
    productApiKey,
    productApiMediaCdnBaseUrl,
    cookieDomain,
    algoliaAppId,
    algoliaSearchApiKey,
    algoliaSearchIndex,
    algoliaSuggestionIndex,
    NextRouter,
    handleUpdateSearchState,
  } = useHeaderContext();

  const algoliaEnvVar: AlgoliaEnvVars = {
    algoliaAppId,
    algoliaSearchApiKey,
    algoliaSearchIndex,
    algoliaSuggestionIndex,
  };

  const { products, loading, setStorageItem, setProducts } = useValidateProducts(
    LocalStorage.RecentlyViewedProducts,
    productApiUrl,
    productApiKey,
    productApiMediaCdnBaseUrl,
    NextRouter
  );

  const localStorageProducts = !loading ? getViewedProducts(Date.now(), products) : [];
  const ignoreChars = 2;
  const debounceValue = 200;

  const { algoliaProducts, algoliaSuggestions, debounced } = useAlgolia(
    algoliaEnvVar,
    searchState.searchTerm,
    debounceValue,
    ignoreChars
  );
  const { topSuggestions } = useCMSGlobal({ prismicRepoEndpoint, prismicToken });

  const algoliaActive: boolean = debounced?.length > ignoreChars;

  // here's a default values placeholder from some of the fields  to prevent unnecessary prop drilling
  // if ever requested by the business that it has to be controlled via navigation service, then we will reimplement its functionality

  const resultCardsArgs: ResultCardsArgs = {
    algoliaActive,
    localStorageProducts,
    algoliaProducts,
    storedValue: products,
    resultsTitle: 'Popular products matching your search',
    initialSearchTerm: 'Recently viewed',
    noResultsText: 'Your recently viewed products will appear here',
  };

  const suggestionArgs: SuggestionsArgs = {
    algoliaActive,
    noResultTitle: 'Search for',
    algoliaSuggestions,
    defaultTitle: 'Popular Searches',
    defaultSuggestions: topSuggestions,
  };

  const searchResultProps = createResultCardProps(resultCardsArgs);
  const suggestionProps = createSuggestionsProps(suggestionArgs);

  const handleOnCardClick = (value: string) => {
    handleUpdateSearchState({ searchTerm: '' });
    const track = createDLAdd(
      algoliaActive,
      algoliaProducts,
      productToDataLayer,
      vpToDataLayer,
      handleUpdateSearchState
    );

    track(value);
    handleLinkClicks();
  };

  const clearHistory = () => {
    setProducts([]);
    setStorageItem(LocalStorage.RecentlyViewedProducts, []);
    setDomainCookie(Cookie.RecentlyViewedProducts, '[]', {
      domain: cookieDomain,
    });
  };

  return (
    <div className="-ml-4 -mr-4 flex h-full flex-col gap-10 overflow-auto p-0 px-4 pb-12 pt-6 lg:grid lg:grid-cols-[auto,1fr] lg:overflow-hidden">
      {suggestionProps && <SearchTermSuggestions {...suggestionProps} />}
      <SearchResultCards
        {...searchResultProps}
        searchTerm={debounced}
        onCardClick={handleOnCardClick}
        onClearClick={clearHistory}
      />
    </div>
  );
};

export default SearchResultWrapper;

type ResultCardsArgs = {
  algoliaActive: boolean;
  localStorageProducts?: SearchResultCardProps[];
  algoliaProducts?: SearchResultCardProps[];
  storedValue: unknown[];
  resultsTitle: string;
  noResultsText: string;
  initialSearchTerm: string;
};

type SuggestionsArgs = {
  algoliaActive: boolean;
  defaultTitle: string;
  noResultTitle: string;
  algoliaSuggestions?: SuggestionsProps[];
  defaultSuggestions?: SuggestionsProps[];
};

const createDLAdd =
  (
    algoliaActive: boolean,
    algoliaProducts: SearchResultCardProps[],
    algoliaAdd: (value: string) => void,
    vpAdd: (value: string) => void,
    handleUpdateSearchState: (value: SearchStateType) => void
  ): ((value: string) => void) =>
  (...args) => {
    // close the overlay
    handleUpdateSearchState({ searchOverlay: false });

    // run tracking onClick
    return algoliaActive && algoliaProducts?.length ? algoliaAdd(...args) : vpAdd(...args);
  };

const createResultCardProps = ({
  algoliaActive,
  localStorageProducts,
  algoliaProducts,
  storedValue,
  resultsTitle,
  noResultsText,
  initialSearchTerm,
}: ResultCardsArgs): Omit<
  SearchResultCardsProps,
  'onClearClick' | 'onCardClick' | 'searchTerm'
> => {
  const showSeeAll = algoliaActive && !!algoliaProducts?.length;
  const cards = showSeeAll ? algoliaProducts : localStorageProducts;
  const resultsTitleValue = showSeeAll ? resultsTitle : initialSearchTerm;
  const showClearHistory = !showSeeAll && !!storedValue?.length;

  return {
    cards: cards ?? [],
    showSeeAll,
    showClearHistory,
    resultsTitle: resultsTitleValue,
    noResultsText,
  };
};

const createSuggestionsProps = ({
  algoliaActive,
  defaultTitle,
  noResultTitle,
  algoliaSuggestions,
  defaultSuggestions,
}: SuggestionsArgs) => {
  const algoliaTitleValue = algoliaSuggestions?.length ? null : noResultTitle;
  const titleValue = algoliaActive ? algoliaTitleValue : defaultTitle;

  return {
    title: titleValue,
    searchSuggestions: algoliaSuggestions,
    defaultSuggestions,
  };
};
